import { LayerMenuItemConfig, SupportedCountries } from '@/typings/layer';
import { layerMenuConfig } from '@/utils/layer';
// import { entries, groupBy, isString } from 'lodash';
import entries from 'lodash/entries';
import groupBy from 'lodash/groupBy';
import isString from 'lodash/isString';

import { ref } from 'vue';
import EventEmitter from 'eventemitter3';

export const eventBus = new EventEmitter();

const activeLayers = ref<Set<string>>(new Set());

const menu = groupBy(entries(layerMenuConfig), (i) => i[1].country) as Record<
  SupportedCountries,
  [string, LayerMenuItemConfig][]
>;

const _toggle_layer = (layerKey) => {
  if (activeLayers.value.has(layerKey)) {
    eventBus.emit('layer-removed', layerMenuConfig[layerKey]);
    activeLayers.value.delete(layerKey);
  } else {
    eventBus.emit('layer-added', layerMenuConfig[layerKey]);
    activeLayers.value.add(layerKey);
  }
};

const toggleLayer = (layerKey: string | string[]) => {
  if (isString(layerKey)) {
    _toggle_layer(layerKey);
  } else {
    layerKey.forEach((key) => {
      _toggle_layer(key);
    });
  }
};

export const useLayers = () => ({ menu, activeLayers, toggleLayer });
