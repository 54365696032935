
import 'mapbox-gl/dist/mapbox-gl.css';
import { Options, Vue } from 'vue-class-component';
import isUrl from 'is-url';
// import { entries, isEmpty, isNil, isString } from 'lodash';

import entries from 'lodash/entries';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

import Loading from './Loading.vue';
import { LayerInformation } from '@/typings/services/layers';
import { LayerCriticality } from '@/typings/layer';
import { getLayersInformation } from '@/services/layers.services';
import AdvisoryList from './AdvisoryList.vue';
import DmoCtaBanner from './DmoCtaBanner.vue';
import { AdvisoryResults } from '@/typings/map';
import SupplementaryData from './SupplementaryData.vue';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Map } from 'mapbox-gl';
import ReportModal from './ReportModal.vue';
import ShareModal from './ShareModal.vue';
import { weather, weatherLocation } from '@/states/homeState';
import { createShareLink } from '@/utils/methods/share-link';
import VSnackbar from 'vuetify/components/VSnackbar';

@Options({
  name: 'FeatureCard',
  components: {
    Loading,
    AdvisoryList,
    DmoCtaBanner,
    SupplementaryData,
    ReportModal,
    ShareModal,
    VSnackbar,
  },
  props: {
    draw: { required: true, type: MapboxDraw },
    map: { required: true, type: Map },
    featureData: { required: true },
    featureLoading: { required: false, default: false, type: Boolean },
    // show: { type: Boolean, required: true, default: false },
  },
  emits: ['onClose', 'toggleLayerDescriptionCard'],
  watch: {
    dialog() {
      if (this.dialog === false) {
        this.showSuccessCopySnackbar = false;
      }
    },
  },
})
export default class FeatureCard extends Vue {
  show = true;
  draw: MapboxDraw;
  map: Map;
  featureLoading: boolean;
  featureData: AdvisoryResults;
  featureProperties: any;
  layerDetails: LayerInformation = null;
  loadingLayerInformation = false;
  name: string;

  showReportModal = false;
  reportDownloading = false;
  fullscreen = false;
  snackbar: { show: boolean; text?: string; status?: 'error' | 'success' | 'info' } = {
    show: false,
  };

  page: 'advisory-list' | 'advisory-details' | 'layer-description' = 'advisory-list';

  stringIsUrl(str: string) {
    if (typeof str == 'string') {
      return isUrl(str?.trim());
    }
    return false;
  }

  get isFeatureData() {
    return Object.values(this.featureData.advisory).reduce((p, c) => c || p, false);
  }

  async downloadReport() {
    this.reportDownloading = true;

    // dynamically loading the required libraries
    // this saves the app loading time + app size
    try {
      const [{ genreateReport }, download] = await Promise.all([
        import('@/utils/methods/report'),
        import('js-file-download').then((d) => d.default),
      ]);

      if (!this.reportDownloading) {
        this.snackbar = {
          show: true,
          status: 'error',
          text: 'No advisory to export.',
        };
      }

      const svgElement = document.querySelector('.mapboxgl-marker > svg') as SVGAElement;
      const clonedSvgElement = svgElement.cloneNode(true) as SVGElement;
      const markerblob = new Blob([new XMLSerializer().serializeToString(clonedSvgElement)], {
        type: 'image/svg+xml',
      });

      const reportBlob = new Promise<Blob>((resolve) => {
        // waiting for map to loading tiles after changin the center
        this.map.once('idle', () => {
          const markerImg = new Image();
          markerImg.onload = async () => {
            const cWidth = map.getCanvas().width;
            const cHeight = map.getCanvas().height;
            const clonedCanvas: HTMLCanvasElement = document.createElement('canvas');
            clonedCanvas.width = cWidth;
            clonedCanvas.height = cHeight;
            const clonedCanvasCtx: CanvasRenderingContext2D = clonedCanvas.getContext('2d');

            clonedCanvasCtx.drawImage(map.getCanvas(), 0, 0);
            const markerH = markerImg.height * 2;
            const markerW = markerImg.width * 2;

            // drawing marker on them image of the map
            clonedCanvasCtx.drawImage(
              markerImg,
              cWidth / 2 - markerW / 2,
              cHeight / 2 - markerH + markerH * 0.1219,
              markerW,
              markerH
            );

            genreateReport({
              mapImageDataURI: clonedCanvas.toDataURL('image/jpeg'),
              featureData: this.featureData,
              weather: weather.value,
              weatherLocation: weatherLocation.value,
              shareLink: createShareLink(this.draw, weatherLocation.value),
            }).getBlob(resolve);
          };
          markerImg.src = URL.createObjectURL(markerblob);
        });
      });

      const map = this.map.setCenter([weatherLocation.value[1], weatherLocation.value[0]]);

      download(
        await reportBlob,
        `Airspace Advisory Report (${weather.value.name}) ${Date.now()}.pdf`
      );

      this.snackbar = {
        show: true,
        status: 'success',
        text: 'Report successfully downloaded',
      };
    } finally {
      this.reportDownloading = false;
    }
  }

  ciriticalityIcon(criticality: LayerCriticality) {
    switch (criticality) {
      case 'Caution - Authorization Required':
        return 'mdi:alert-outline';
      case 'Caution - Information':
        return 'mdi:alert-circle-outline';
      case 'Prohibited':
        return 'mdi:cancel';
      default:
        return 'mdi:check-circle-outline';
    }
  }

  ciriticalityHeading(criticality: LayerCriticality) {
    switch (criticality) {
      case 'Caution - Information':
        return 'Additional advisories by Watchtower';
      case 'Prohibited':
        return 'Prohibited - No-Drone zone';
      default:
        return criticality;
    }
  }

  processProperties(properties: Record<string, any>) {
    return entries(properties);
  }

  valueExist(value) {
    return !(isNil(value) || (isString(value) && isEmpty(value.trim())));
  }

  fetchLayerInfromation = async (layerGroupId: string) => {
    this.loadingLayerInformation = true;
    const result = await getLayersInformation({ layerId: layerGroupId });
    if (result.data.success) {
      this.layerDetails = result.data.layer;
    }
    this.loadingLayerInformation = false;
  };

  toggleLayerDescriptionCard(e: MouseEvent, groupLayerId: string) {
    e.preventDefault();
    e.stopPropagation();

    this.loadingLayerInformation = true;
    this.page = 'layer-description';
    this.fetchLayerInfromation(groupLayerId);
    // this.$emit('toggleLayerDescriptionCard', layerKey);
  }

  onPlaceClick(properties: Record<string, any>) {
    this.featureProperties = properties;
    this.page = 'advisory-details';
  }

  onClose() {
    this.$emit('onClose', true);
  }
}
