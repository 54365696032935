import mapboxgl from 'mapbox-gl';

export const layersSource: mapboxgl.Sources = {
  nd_near_airport_5miles_46324: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/nd_near_airport_5miles_46324/{z}/{x}/{y}.mvt`],
  },
  nd_urban_area: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/nd_urban_area/{z}/{x}/{y}.mvt`],
  },
  nd_nuclear_buffered_800m: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/nd_nuclear_buffered_800m/{z}/{x}/{y}.mvt`],
  },
  special_use_airspace_risk: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/special_use_airspace_risk/{z}/{x}/{y}.mvt`],
  },
  wls_parks: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/wls_parks/{z}/{x}/{y}.mvt`],
  },
  merge25kmintboundary: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/merge25kmintboundary/{z}/{x}/{y}.mvt`],
  },
  buffer_8km: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/buffer_8km/{z}/{x}/{y}.mvt`],
  },
  new5kmbuffer: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/new5kmbuffer/{z}/{x}/{y}.mvt`],
  },
  newbuffer12km: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/newbuffer12km/{z}/{x}/{y}.mvt`],
  },
  classb_airspace_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/classb_airspace_usa/{z}/{x}/{y}.mvt`],
  },
  classc_airspace_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/classc_airspace_usa/{z}/{x}/{y}.mvt`],
  },
  classd_airspace_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/classd_airspace_usa/{z}/{x}/{y}.mvt`],
  },
  classe2_airspace_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/classe2_airspace_usa/{z}/{x}/{y}.mvt`],
  },
  prohibited_areas_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/prohibited_areas_usa/{z}/{x}/{y}.mvt`],
  },
  faa_uas_facilitymap_data_v5_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [
      `${process.env.VUE_APP_API_HOST}/tiles/faa_uas_facilitymap_data_v5_usa/{z}/{x}/{y}.mvt`,
    ],
  },
  national_security_uas_flight_restrictions_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [
      `${process.env.VUE_APP_API_HOST}/tiles/national_security_uas_flight_restrictions_usa/{z}/{x}/{y}.mvt`,
    ],
  },
  part_time_national_security_uas_flight_restrictions_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [
      `${process.env.VUE_APP_API_HOST}/tiles/part_time_national_security_uas_flight_restrictions_usa/{z}/{x}/{y}.mvt`,
    ],
  },
  pending_national_security_uas_flight_restrictions_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [
      `${process.env.VUE_APP_API_HOST}/tiles/pending_national_security_uas_flight_restrictions_usa/{z}/{x}/{y}.mvt`,
    ],
  },
  recreational_flyer_fixed_sites_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [
      `${process.env.VUE_APP_API_HOST}/tiles/recreational_flyer_fixed_sites_usa/{z}/{x}/{y}.mvt`,
    ],
  },
  specialuseairspace_usa: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/specialuseairspace_usa/{z}/{x}/{y}.mvt`],
  },
  buffer3km_ss: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/buffer3km_ss/{z}/{x}/{y}.mvt`],
  },
  redzone_india: {
    type: 'vector',
    maxzoom: 9,
    tiles: [`${process.env.VUE_APP_API_HOST}/tiles/redzone_india/{z}/{x}/{y}.mvt`],
  },
};
