import './main.scss';
import { createVuetify } from 'vuetify';
import { VTooltip } from 'vuetify/components/VTooltip';
import { VDialog } from 'vuetify/components/VDialog';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VTooltip,
    VDialog,
  },
  theme: false,
});
