import Axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import routers from './routers';
import './styles/index.scss';
import { Icon } from '@iconify/vue';
import UICollapse from './components/UICollapse.vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import vuetify from './plugins/vuetify';

Axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
Axios.defaults.headers.common['X-API-Key'] = process.env.VUE_APP_BACKEND_API_KEY;

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  logErrors: true,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(routers),
      tracingOrigins: [
        'localhost:5000',
        'watchtower-test-fe.skylarkdrones.com',
        'watchtower-staging.skylarkdrones.com',
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  debug: true,
  attachStacktrace: true,
  environment: process.env.VUE_APP_SENTRY_ENV,
  release: process.env.VUE_APP_VERSION,
});

app
  .use(routers)
  .use(vuetify)
  .component('Icon', Icon)
  .component('ui-collapse', UICollapse)
  .mount('#app');
