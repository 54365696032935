import { LayersMenuConfig } from '@/typings/layer';

export const layerMenuConfig: LayersMenuConfig = {
  // all
  nd_near_airport_5miles_46324: {
    label: 'Global - Airports 5miles Buffer',
    layers: ['nd_near_airport_5miles_46324'],
    country: 'global',
    groupLayerId: '21',
  },
  nd_urban_area: {
    label: 'Global - Urban Areas',
    layers: ['nd_urban_area'],
    country: 'global',
    groupLayerId: '18',
  },
  special_use_airspace_risk: {
    label: 'Global - Special Use Airspace',
    country: 'global',
    layers: ['special_use_airspace_risk'],
    groupLayerId: '22',
  },
  nd_nuclear_buffered_800m: {
    label: 'Global - Nuclear Plants',
    country: 'global',
    layers: ['nd_nuclear_buffered_800m'],
    groupLayerId: '19',
  },

  // usa
  prohibited_areas_usa: {
    label: 'USA - Prohibited Areas',
    country: 'usa',
    layers: ['prohibited_areas_usa'],
    groupLayerId: '5',
  },
  faa_uas_facilitymap_data_v5_usa: {
    label: 'USA - FAA UAS Facility',
    country: 'usa',
    layers: ['faa_uas_facilitymap_data_v5_usa'],
    groupLayerId: '8',
  },
  recreational_flyer_fixed_sites_usa: {
    label: 'Recreational Flyer Fixed Sites',
    country: 'usa',
    layers: ['recreational_flyer_fixed_sites_usa'],
    groupLayerId: '7',
  },
  specialuseairspace_usa: {
    label: 'USA - Special Use Airspace',
    country: 'usa',
    layers: ['specialuseairspace_usa'],
    groupLayerId: '5',
  },
  controled_airspace: {
    label: 'USA - Controlled Airspace B,C,D,E',
    country: 'usa',
    layers: [
      'classb_airspace_usa',
      'classc_airspace_usa',
      'classd_airspace_usa',
      'classe2_airspace_usa',
    ],
    groupLayerId: '1',
  },
  flight_restriction: {
    label: 'USA - Flight Restrictions',
    country: 'usa',
    layers: [
      'national_security_uas_flight_restrictions_usa',
      'part_time_national_security_uas_flight_restrictions_usa',
      'pending_national_security_uas_flight_restrictions_usa',
    ],
    groupLayerId: '9',
  },

  // india
  buffer3km_ss: {
    label: 'India - Secretariat',
    country: 'india',
    layers: ['buffer3km_ss'],
    groupLayerId: '16',
  },
  wls_parks: {
    label: 'India - Wild Life Santuaries & Parks',
    country: 'india',
    layers: ['wls_parks'],
    groupLayerId: '15',
  },
  merge25kmintboundary: {
    label: 'India - International Boundary',
    country: 'india',
    layers: ['merge25kmintboundary'],
    groupLayerId: '14',
  },
  new5kmbuffer: {
    label: 'India - Airports Red Zone',
    country: 'india',
    layers: ['new5kmbuffer'],
    groupLayerId: '11',
  },
  buffer_8km: {
    label: 'India - Yellow Zone',
    country: 'india',
    layers: ['buffer_8km'],
    groupLayerId: '12',
  },
  newbuffer12km: {
    label: 'India - Yellow Zone above 200ft',
    country: 'india',
    layers: ['newbuffer12km'],
    groupLayerId: '13',
  },
  redzone_india: {
    label: 'India - Red Zones',
    country: 'india',
    layers: ['redzone_india'],
    groupLayerId: '23',
  },

};
