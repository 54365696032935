import { GetWeatherForecaste3H5DResponse, GetWeatherResponse } from '@/typings/services/weather';
import axios, { CancelToken } from 'axios';

export function getWeather(latitude: number, longitude: number, cancelToken: CancelToken) {
  return axios.get<GetWeatherResponse>(`/weather`, {
    params: { latitude, longitude },
    cancelToken,
  });
}

export function getWeatherForecast3H5D(latitude: number, longitude: number) {
  return axios.get<GetWeatherForecaste3H5DResponse>(`/weather/24h`, {
    params: { latitude, longitude },
  });
}
