
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'UICollapse',
  props: { open: { required: false, type: Boolean, default: false } },
})
export default class UICollapse extends Vue {
  open = false;
  collapsed = true;
  collapsableHeight = '0px';

  mounted() {
    this.collapsed = !this.open;
    if (!this.collapsed) {
      this.collapsableHeight = `${this.getUnCollapsedHeight()}px`;
    }
  }

  getUnCollapsedHeight() {
    return (this.$refs.collapsable as HTMLElement).clientHeight;
  }

  toggleCollapsable() {
    this.collapsed = !this.collapsed;
    this.collapsableHeight = `${this.getUnCollapsedHeight()}px`;

    // making height to automatically adjust according to the child collapsable
    if (!this.collapsed) {
      setTimeout(() => {
        this.collapsableHeight = 'auto';
      }, 300);
    }

    if (this.collapsed) {
      setTimeout(() => {
        this.collapsableHeight = `0px`;
      }, 0);
    }
  }
}
