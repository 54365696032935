import { GetLayerInformationResponse } from '@/typings/services/layers';
import axios from 'axios';

export function getLayersInformation(query: { layerId?: string; layerCategoryId?: string }) {
  if (typeof query !== 'object' || Object.keys(query).length !== 1) {
    throw new Error(
      "Value for query cannot be empty, Required either 'layerId' or 'layerCategory'"
    );
  }

  return axios.get<GetLayerInformationResponse>(`/layer`, {
    params: {
      ...('layerId' in query && { layerId: query.layerId }),
      ...('layerCategoryId' in query && { layerCategory: query.layerCategoryId }),
    },
  });
}
