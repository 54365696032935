import mapboxgl from 'mapbox-gl';

export const layerMapboxOptions: Record<string, mapboxgl.AnyLayer> = {
  nd_near_airport_5miles_46324: {
    id: 'nd_near_airport_5miles_46324',
    type: 'fill',
    source: 'nd_near_airport_5miles_46324',
    'source-layer': 'nd_near_airport_5miles_46324',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(214, 40, 40, 0.05)',
      'fill-outline-color': '#D62828',
    },
  },
  nd_urban_area: {
    id: 'nd_urban_area',
    type: 'fill',
    source: 'nd_urban_area',
    'source-layer': 'nd_urban_area',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(0,0,0, 0)',
      // 'fill-opacity': 0.2,
      'fill-outline-color': 'rgba(127, 85, 57, 0.5)',
    },
  },
  nd_nuclear_buffered_800m: {
    id: 'nd_nuclear_buffered_800m',
    type: 'fill',
    source: 'nd_nuclear_buffered_800m',
    'source-layer': 'nd_nuclear_buffered_800m',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#0077b6',
      'fill-opacity': 0.6,
    },
  },
  special_use_airspace_risk: {
    id: 'special_use_airspace_risk',
    type: 'fill',
    source: 'special_use_airspace_risk',
    'source-layer': 'special_use_airspace_risk',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(218, 215, 205, 0.25)',
      'fill-outline-color': '#D4D4D4',
    },
  },
  vijaychowk_1kmbuffer: {
    id: 'vijaychowk_1kmbuffer',
    type: 'fill',
    source: 'vijaychowk_1kmbuffer',
    'source-layer': 'vijaychowk_1kmbuffer',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#d62828',
      'fill-opacity': 0.6,
    },
  },
  wls_parks: {
    id: 'wls_parks',
    type: 'fill',
    source: 'wls_parks',
    'source-layer': 'wls_parks',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(191, 210, 0, 0.1)',
      'fill-outline-color': '#bfd200',
    },
  },
  merge25kmintboundary: {
    id: 'merge25kmintboundary',
    type: 'fill',
    source: 'merge25kmintboundary',
    'source-layer': 'merge25kmintboundary',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(214, 40, 40, 0.05)',
      'fill-outline-color': '#D62828',
    },
  },
  buffer_8km: {
    id: 'buffer_8km',
    type: 'fill',
    source: 'buffer_8km',
    'source-layer': 'buffer_8km',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(255, 255, 0, 0.1)',
      'fill-outline-color': '#ffd60a',
    },
  },
  new5kmbuffer: {
    id: 'new5kmbuffer',
    type: 'fill',
    source: 'new5kmbuffer',
    'source-layer': 'new5kmbuffer',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(214, 40, 40, 0.05)',
      'fill-outline-color': '#D62828',
    },
  },
  newbuffer12km: {
    id: 'newbuffer12km',
    type: 'fill',
    source: 'newbuffer12km',
    'source-layer': 'newbuffer12km',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(245, 245, 122, 0.2)',
      'fill-outline-color': '#ffd60a',
    },
  },
  classb_airspace_usa: {
    id: 'classb_airspace_usa',
    type: 'fill',
    source: 'classb_airspace_usa',
    'source-layer': 'classb_airspace_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#bee8ff',
      'fill-opacity': 0.6,
    },
  },
  classc_airspace_usa: {
    id: 'classc_airspace_usa',
    type: 'fill',
    source: 'classc_airspace_usa',
    'source-layer': 'classc_airspace_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#bee8ff',
      'fill-opacity': 0.6,
    },
  },
  classd_airspace_usa: {
    id: 'classd_airspace_usa',
    type: 'fill',
    source: 'classd_airspace_usa',
    'source-layer': 'classd_airspace_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#0059e6',
      'fill-opacity': 0.6,
    },
  },
  classe2_airspace_usa: {
    id: 'classe2_airspace_usa',
    type: 'fill',
    source: 'classe2_airspace_usa',
    'source-layer': 'classe2_airspace_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#ffbbe7',
      'fill-opacity': 0.6,
    },
  },
  prohibited_areas_usa: {
    id: 'prohibited_areas_usa',
    type: 'fill',
    source: 'prohibited_areas_usa',
    'source-layer': 'prohibited_areas_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#bed2ff',
      'fill-opacity': 0.6,
    },
  },
  faa_uas_facilitymap_data_v5_usa: {
    id: 'faa_uas_facilitymap_data_v5_usa',
    type: 'fill',
    source: 'faa_uas_facilitymap_data_v5_usa',
    'source-layer': 'faa_uas_facilitymap_data_v5_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#f5f57a',
      'fill-opacity': 0.6,
    },
  },
  national_security_uas_flight_restrictions_usa: {
    id: 'national_security_uas_flight_restrictions_usa',
    type: 'fill',
    source: 'national_security_uas_flight_restrictions_usa',
    'source-layer': 'national_security_uas_flight_restrictions_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#d62828',
      'fill-opacity': 0.6,
    },
  },
  part_time_national_security_uas_flight_restrictions_usa: {
    id: 'part_time_national_security_uas_flight_restrictions_usa',
    type: 'fill',
    source: 'part_time_national_security_uas_flight_restrictions_usa',
    'source-layer': 'part_time_national_security_uas_flight_restrictions_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#d62828',
      'fill-opacity': 0.6,
    },
  },
  pending_national_security_uas_flight_restrictions_usa: {
    id: 'pending_national_security_uas_flight_restrictions_usa',
    type: 'fill',
    source: 'pending_national_security_uas_flight_restrictions_usa',
    'source-layer': 'pending_national_security_uas_flight_restrictions_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#d62828',
      'fill-opacity': 0.6,
    },
  },
  recreational_flyer_fixed_sites_usa: {
    id: 'recreational_flyer_fixed_sites_usa',
    type: 'fill',
    source: 'recreational_flyer_fixed_sites_usa',
    'source-layer': 'recreational_flyer_fixed_sites_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#007f5f',
      'fill-opacity': 0.6,
    },
  },
  specialuseairspace_usa: {
    id: 'specialuseairspace_usa',
    type: 'fill',
    source: 'specialuseairspace_usa',
    'source-layer': 'specialuseairspace_usa',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#dad7cd',
      'fill-opacity': 0.6,
    },
  },

  buffer3km_ss: {
    id: 'buffer3km_ss',
    type: 'fill',
    source: 'buffer3km_ss',
    'source-layer': 'buffer3km_ss',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(252, 163, 17, 0.1)',
      'fill-outline-color': '#fca311',
    },
  },
  export_trainingarea1: {
    id: 'export_trainingarea1',
    type: 'fill',
    source: 'export_trainingarea1',
    'source-layer': 'export_trainingarea1',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#f5f57a',
      'fill-opacity': 0.6,
    },
  },
  export_trainingarea2: {
    id: 'export_trainingarea2',
    type: 'fill',
    source: 'export_trainingarea2',
    'source-layer': 'export_trainingarea2',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#f5f57a',
      'fill-opacity': 0.6,
    },
  },
  exportrange1: {
    id: 'exportrange1',
    type: 'fill',
    source: 'exportrange1',
    'source-layer': 'exportrange1',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#f5f57a',
      'fill-opacity': 0.6,
    },
  },
  exportrange2: {
    id: 'exportrange2',
    type: 'fill',
    source: 'exportrange2',
    'source-layer': 'exportrange2',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#f5f57a',
      'fill-opacity': 0.6,
    },
  },
  india_composite: {
    id: 'india_composite',
    type: 'fill',
    source: 'india_composite',
    'source-layer': 'india_composite',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#f5f57a',
      'fill-opacity': 0.6,
    },
  },
  intboundaryline: {
    id: 'intboundaryline',
    type: 'line',
    source: 'intboundaryline',
    'source-layer': 'intboundaryline',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'line-color': '#a80000',
      'line-width': 2,
    },
  },
  pointstopolygon_refined: {
    id: 'pointstopolygon_refined',
    type: 'fill',
    source: 'pointstopolygon_refined',
    'source-layer': 'pointstopolygon_refined',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#6e1714',
      'fill-opacity': 0.6,
    },
  },
  secretariat: {
    id: 'secretariat',
    type: 'fill',
    source: 'secretariat',
    'source-layer': 'secretariat',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#d62828',
      'fill-opacity': 0.6,
    },
  },
  vijaychowk: {
    id: 'vijaychowk',
    type: 'fill',
    source: 'vijaychowk',
    'source-layer': 'vijaychowk',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': '#d62828',
      'fill-opacity': 0.6,
    },
  },
  redzone_india: {
    id: 'redzone_india',
    type: 'fill',
    source: 'redzone_india',
    'source-layer': 'redzone_india',
    minzoom: 0,
    maxzoom: 22,
    paint: {
      'fill-color': 'rgba(214, 40, 40, 0.05)',
      'fill-outline-color': '#D62828',
    },
  },
};
