<template>
  <div class="cta-dmo-ct">
    <img
      src="https://uploads-ssl.webflow.com/5d490ccd3cf49e0bd0ec972e/5e2adb2e718fe0c91690bd60_Skylark%20Webclip.png"
      alt="skylark drones"
      class="w-8 row-span-2 mr-3 mt-2"
    />
    <h4 class="font-semibold">Airspace checked? Now let’s Fly 🚀</h4>
    <p class="col-start-2 text-xs mt-1">
      Check out <b class="font-semibold">Drone Mission Ops</b>, Our flagship operations platform to
      plan your flights here.
    </p>
    <a href="https://dronemissionops.com/" class="cta-dmo-btn">Sign Up For Free</a>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.cta-dmo-ct {
  background: rgba(255, 102, 0, 0.1);
  @apply p-4 -mx-4 justify-center mb-4 grid text-sm;
  grid-template-columns: auto 1fr;

  .cta-dmo-btn {
    background: #f60;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    @apply p-2 col-span-full text-center mt-3 w-full rounded-md text-xs tracking-wide uppercase text-white cursor-pointer transition-all;

    &:hover {
      background: darken($color: #f60, $amount: 2);
    }
  }
}
</style>
