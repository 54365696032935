<template>
  <div class="loading-ct">
    <div class="loading-icon">
      <Icon class="icon" icon="mdi:loading" font-size="16" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.loading-ct {
  @apply flex flex-col items-center justify-center p-3;
}

.loading-icon {
  @apply animate-spin;
  animation-duration: 500ms;
}
</style>
