import { computed, ref } from 'vue';
import formatcoords from 'formatcoords';
import { Weather } from '@/typings/services/weather';
import axios, { CancelTokenSource } from 'axios';
import { getWeather } from '@/services/weather.services';
import { AdvisoryResults } from '@/typings/map';

export const showResults = ref(false);
export const weatherLocation = ref<[number, number]>();
export const weather = ref<Weather>();
export const featureCardData = ref<AdvisoryResults>();
export const featureLoading = ref(false);

export const currLoc = computed(() =>
  weatherLocation.value
    ? formatcoords(weatherLocation.value).format('-d', { latLonSeparator: ',', decimalPlaces: 6 })
    : null
);

let cancelToken: CancelTokenSource;
export const showWeatherForCoordinate = async (location: { lat: number; lng: number }) => {
  if (cancelToken) {
    cancelToken.cancel();
  }
  try {
    weatherLocation.value = [location.lat, location.lng];
    cancelToken = axios.CancelToken.source();
    const result = await getWeather(location.lat, location.lng, cancelToken.token);
    if (result.data.success) {
      weather.value = result.data.weather;
    }
  } catch (error) {}
};
