import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56d24824"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collapsable-ct" }
const _hoisted_2 = {
  ref: "collapsable",
  class: "collapsable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "collapse-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCollapsable()))
    }, [
      _renderSlot(_ctx.$slots, "button", {
        isOpen: !_ctx.collapsed
      }, undefined, true)
    ]),
    _createElementVNode("div", {
      class: "collapsable-wrapper",
      style: _normalizeStyle({ height: _ctx.collapsableHeight })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {
          isOpen: !_ctx.collapsed
        }, undefined, true)
      ], 512)
    ], 4)
  ]))
}