import simplify from '@turf/simplify';
import { polygon, Position } from '@turf/helpers';
import { stringify } from 'qs';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export type ShareCode = string;
export const generateShareCode = (draw: MapboxDraw, currentLocation: Position): ShareCode => {
  if (draw.getSelected().features.length > 0) {
    const feature = draw.getSelected().features[0];
    switch (feature.properties.shape) {
      case 'circle':
        return btoa(
          JSON.stringify({
            shape: 'circle',
            center: feature.properties.center,
            radius: feature.properties.radiusInKm,
          })
        );
      case 'polygon':
        return btoa(
          JSON.stringify({
            shape: 'polygon',
            polygon: simplify(polygon((feature.geometry as any).coordinates), {
              tolerance: 0.0005,
            }).geometry.coordinates,
          })
        );
    }
  } else {
    return btoa(JSON.stringify({ shape: 'point', coord: currentLocation }));
  }
};

export const createShareLink = (draw: MapboxDraw, currentLocation: Position) => {
  return encodeURI(
    location.origin +
      stringify(
        { share: generateShareCode(draw, currentLocation) },
        { encode: false, addQueryPrefix: true }
      )
  );
};
