import { LayerMenuItemConfig } from '@/typings/layer';
import mapboxgl, { FillLayer, MapboxOptions } from 'mapbox-gl';
import { layerDescription, layerMapboxOptions, layersSource } from './layer';

export const mapboxConfig: MapboxOptions = {
  container: 'map',
  zoom: 11,
  // minZoom: 9,
  center: [77.71, 12.9], // BLR
  dragRotate: false,
  touchPitch: false,
  pitchWithRotate: false,
  preserveDrawingBuffer: true,
  transformRequest: (url, resourceType) => {
    if (resourceType === 'Tile' && url.includes(process.env.VUE_APP_API_HOST)) {
      return {
        url,
        headers: { 'X-API-Key': process.env.VUE_APP_BACKEND_API_KEY },
      };
    }
    return { url };
  },
  style: {
    version: 8,
    sources: {
      'street-view': {
        type: 'raster',
        tiles: [
          `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_BASEMAP_ACCESS_TOKEN}`,
        ],
      },
      'satellite-view': {
        type: 'raster',
        tiles: [
          `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_BASEMAP_ACCESS_TOKEN}`,
        ],
      },
      ...layersSource,
    },
    layers: [
      {
        id: 'street-view-layer',
        type: 'raster',
        source: 'street-view',
        minzoom: 0,
        maxzoom: 22,
      },
    ],
  },
};

export function removeMapLayer(map: mapboxgl.Map, layerId: LayerMenuItemConfig['layers']) {
  layerId.forEach((layerId: string) => {
    const layer = map.getLayer(layerId);

    if (layer) {
      map.removeLayer(layerId);
    }
  });
}

export function addMapLayer(map: mapboxgl.Map, groupedLayer: LayerMenuItemConfig) {
  groupedLayer.layers.forEach((layer) =>
    map.addLayer({
      ...layerMapboxOptions[layer],
      minzoom: 9,
      metadata: {
        layerId: groupedLayer.groupLayerId,
        criticality: layerDescription[layer].criticality,
      },
    } as FillLayer)
  );
}
