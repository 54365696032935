import { LayersInformationConfig } from '@/typings/layer';

export const layerDescription: LayersInformationConfig = {
  nd_near_airport_5miles_46324: {
    label: 'Airports 5miles Buffer',
    groupLayerId: '21',
    criticality: 'Prohibited',
  },
  nd_urban_area: {
    label: 'Urban Areas',
    groupLayerId: '18',
    criticality: 'Caution - Information',
  },

  classb_airspace_usa: {
    label: 'Class B Airspace',
    country: 'usa',
    groupLayerId: '1',
    criticality: 'Caution - Authorization Required',
  },
  classc_airspace_usa: {
    label: 'Class C Airspace',
    country: 'usa',
    groupLayerId: '1',
    criticality: 'Caution - Authorization Required',
  },
  classd_airspace_usa: {
    label: 'Class D Airspace',
    country: 'usa',
    groupLayerId: '1',
    criticality: 'Caution - Authorization Required',
  },
  classe2_airspace_usa: {
    label: 'Class E Airspace',
    country: 'usa',
    groupLayerId: '1',
    criticality: 'Caution - Authorization Required',
  },
  prohibited_areas_usa: {
    label: 'Prohibited Areas',
    country: 'usa',
    groupLayerId: '5',
    criticality: 'Caution - Information',
  },
  faa_uas_facilitymap_data_v5_usa: {
    label: 'FAA UAS Facility',
    country: 'usa',
    groupLayerId: '8',
    criticality: 'Caution - Authorization Required',
  },
  national_security_uas_flight_restrictions_usa: {
    label: 'National Security UAS Flight Restrictions',
    country: 'usa',
    groupLayerId: '9',
    criticality: 'Prohibited',
  },
  part_time_national_security_uas_flight_restrictions_usa: {
    label: 'Part Time National Security UAS Flight Restrictions',
    country: 'usa',
    groupLayerId: '9',
    criticality: 'Prohibited',
  },
  pending_national_security_uas_flight_restrictions_usa: {
    label: 'Pending National Security UAS Flight Restrictions',
    country: 'usa',
    groupLayerId: '9',
    criticality: 'Prohibited',
  },
  recreational_flyer_fixed_sites_usa: {
    label: 'Recreational Flyer Fixed Sites',
    country: 'usa',
    groupLayerId: '7',
    criticality: 'Free',
  },
  specialuseairspace_usa: {
    label: 'Special Use Airspace',
    country: 'usa',
    groupLayerId: '5',
    criticality: 'Caution - Information',
  },

  buffer3km_ss: {
    label: 'Secretariat',
    country: 'india',
    groupLayerId: '16',
    criticality: 'Caution - Information',
  },
  nd_nuclear_buffered_800m: {
    label: 'Nuclear Plants',
    country: 'india',
    groupLayerId: '19',
    criticality: 'Caution - Information',
  },
  special_use_airspace_risk: {
    label: 'Special Use Airspace',
    country: 'india',
    groupLayerId: '22',
    criticality: 'Caution - Information',
  },
  wls_parks: {
    label: 'Wild Life Santuaries & Parks',
    country: 'india',
    groupLayerId: '15',
    criticality: 'Caution - Information',
  },
  merge25kmintboundary: {
    label: 'International Boundary',
    country: 'india',
    groupLayerId: '14',
    criticality: 'Prohibited',
  },
  new5kmbuffer: {
    label: 'Airports Red Zone',
    country: 'india',
    groupLayerId: '11',
    criticality: 'Prohibited',
  },
  buffer_8km: {
    label: 'Yellow Zone',
    country: 'india',
    groupLayerId: '12',
    criticality: 'Caution - Authorization Required',
  },
  newbuffer12km: {
    label: 'Yellow Zone above 200ft',
    country: 'india',
    groupLayerId: '13',
    criticality: 'Caution - Authorization Required',
  },
  redzone_india: {
    label: 'Red Zones',
    country: 'india',
    groupLayerId: '23',
    criticality: 'Prohibited',
  },
};
