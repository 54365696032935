import {
  GetCirlceAdvisoryResponse,
  GetPointAdvisoryResponse,
  GetPolygonAdvisoryResponse,
  GetRectangleAdvisoryResponse,
} from '@/typings/services/advisory';
import axios from 'axios';

export function getAdvisoryByCircle(lat: number, long: number, radius: number) {
  return axios.get<GetCirlceAdvisoryResponse>(`/advisory/circle`, {
    params: { radius, point: `${long} ${lat}` },
  });
}

export function getAdvisoryByPolygon(polygonPoints: number[][]) {
  return axios.post<GetPolygonAdvisoryResponse>(`/advisory/polygon`, {
    polygon: polygonPoints.map((point) => point.join(' ')).join(', '),
  });
}

export function getAdvisoryBySquare(lat1: number, long1: number, lat2: number, long2: number) {
  return axios.get<GetRectangleAdvisoryResponse>(`/advisory`, {
    params: { lat1, long1, lat2, long2 },
  });
}

export function getAdvisoryByPoint(lat: number, long: number) {
  return axios.get<GetPointAdvisoryResponse>(`/advisory/point`, {
    params: { point: `${long} ${lat}` },
  });
}
