import axios from 'axios';

export function postReport(query: {
  email?: string;
  description: string;
  location: string;
  link: string;
  type: string;
}) {
  return axios.post<{ success: true }>(`/report`, query);
}
